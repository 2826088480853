import React, { useEffect } from 'react'
import './Dashboard.css'
// import CountUp from 'react-countup'

function Dashboard() {

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div>
    </div>
  )
}

export default Dashboard