import React from 'react';
import './App.css';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import SideNav from './components/SideNav/SideNav';
import Header from './components/Header/Header';
import Dashboard from './pages/Dashboard/Dashboard';
import Login from './pages/Login/Login';
import NotFound from './pages/NotFound';
import Staff from './pages/Staff/Staff';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <SideNav />
        <div className='p-3 w-100 h-100' style={{ overflowY: 'auto' }}>
          <Header />
          <ToastContainer position="bottom-right" autoClose={3000} hideProgressBar={true} closeButton={false} />
          <Routes>
            <Route path='/' element={<Login />} />
            <Route path='*' element={<Navigate to='/404' replace />} />
            <Route path='/404' element={<NotFound />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path='/staff' element={<Staff />} />
          </Routes>
        </div>
      </BrowserRouter>
    </div>
  );
}

export default App;
