import { ILoginUser } from "./Interfaces";

export function getLocalStorageUser() {
  let user = JSON.parse(localStorage.getItem("user") ?? "{}");

  let userData: ILoginUser = { uid: "", email: "", name: "", userRole: "", profilePic: "" };

  if (userData) {
    userData = user;
  }

  return userData;
}