import React, { useContext, useState } from 'react'
import { Menu, MenuItem, Sidebar, useProSidebar } from 'react-pro-sidebar'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { Dashboard,Logout, AssignmentInd } from '@mui/icons-material/';
import { FilledButton } from '../CustomMui/CustomMui';
import { Avatar, CircularProgress } from '@mui/material';
import './SideNav.css'
import firebase from '../../Services/FirebaseConfig';
import { UserContext, UserContextProps } from '../../context/UserContext';

function SideNav() {
  const { user, updateUser } = useContext(UserContext) as UserContextProps;
  const location = useLocation()
  const navigate = useNavigate()
  const { toggleSidebar, collapseSidebar } = useProSidebar();
  const [isLoading, setIsLoading] = useState(false as boolean)

  async function handleLogOut() {
    setIsLoading(true);
    await firebase.auth().signOut();
    localStorage.removeItem("user");
    updateUser({ uid: "", email: "", name: "", userRole: "", profilePic: "" });
    setIsLoading(false);
    navigate('/')
    collapseSidebar(true)
  }

  return (
    <Sidebar
      backgroundColor='var(--primary)'
      breakPoint='xl'
      collapsedWidth='0px'
      style={{ zIndex: 999, border: 'none' }}
    >
      <div className='sidebar py-5'>
        <div>
          <img src='/images/Logo_White.png' alt='logo' className='sidebar-logo mb-5 img-fluid' />
          <Menu>
            <MenuItem
              active={location.pathname === '/dashboard'}
              icon={<Dashboard />}
              onClick={() => toggleSidebar(false)}
              component={<Link to="/dashboard" />}
            >
              Dashboard
            </MenuItem>
            <MenuItem
              active={location.pathname === '/staff'}
              icon={<AssignmentInd />}
              onClick={() => toggleSidebar(false)}
              component={<Link to="/staff" />}
            >
              Staff Management
            </MenuItem>
          </Menu>
        </div>
        <div className='text-center'>
          {isLoading === false ? (
            <div className='d-flex mb-4'>
              <Avatar src={user?.profilePic ? user?.profilePic : '/images/User.jpg'} alt='profile photo' />
              <div className='ms-2'>
                <h6 className='text-start mb-0'>{user?.name}</h6>
                <p className='text-start mb-0'>{user?.userRole}</p>
              </div>
            </div>
          ) : (
            <CircularProgress className='mb-4' color='inherit' />
          )}
          <FilledButton
            className='w-100'
            startIcon={<Logout />}
            onClick={() => handleLogOut()}
          >
            Logout
          </FilledButton>
        </div>
      </div>
    </Sidebar>
  )
}

export default SideNav