import React, { useEffect } from "react";
import { useProSidebar } from 'react-pro-sidebar';
import { useNavigate } from "react-router-dom";

const NotFound = () => {
  const navigate = useNavigate()
  const { collapseSidebar } = useProSidebar();

  useEffect(() => {
    collapseSidebar(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="vh-100 text-center flex-center">
      <div className="w-100">
        <h1 className="text-red">404 - Page Not Found</h1>
        <p>The page you are looking for might have been removed or is temporarily unavailable.</p>
        <h5 className="text-blue" onClick={() => { navigate(-1); collapseSidebar(false) }}>Go back</h5>
      </div>
    </div>
  );
};

export default NotFound;