import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Carousel } from "react-bootstrap";
import { useProSidebar } from 'react-pro-sidebar';
import './Login.css'
import { toast } from "react-toastify";
import { Button, CircularProgress, IconButton, InputAdornment, TextField } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import firebase from '../../Services/FirebaseConfig';
import { UserContext, UserContextProps } from "../../context/UserContext";
import { ILoginUser } from "../../helpers/Interfaces";

export default function Login() {
  const { updateUser } = useContext(UserContext) as UserContextProps;
  const navigate = useNavigate()
  const location = useLocation()
  const { collapseSidebar } = useProSidebar();
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("" as string);
  const [password, setPassword] = useState("" as string);
  const [showPassword, setShowPassword] = useState(false as boolean)

  useEffect(() => {
    collapseSidebar(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  async function handleLogin(event: { preventDefault: () => void }) {
    event.preventDefault();
    setIsLoading(true);
    try {
      const result = await firebase.auth().signInWithEmailAndPassword(email, password);

      const userData = await firebase.app().firestore().collection("users").doc(result.user?.uid).get()

      if (userData.data()?.userRole !== 'admin') {
        firebase.auth().signOut();
        throw new Error("You are not authorized to access this system");
      }
      localStorage.setItem("user", JSON.stringify(userData.data()))
      updateUser(userData.data() as ILoginUser);
      toast.success("Welcome " + userData.data()?.name as string);
      navigate("/dashboard")
      collapseSidebar(false)
    } catch (error: any) {
      toast.error(error.message);
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <div className="login row align-items-center h-100">
      <div className="col-xl-4 p-5 text-center">
        <img src='/images/Logo_Black.png' className="img-fluid mb-5" width={220} alt="logo" />
        <h2>Welcome To </h2>
        <h1>Admin Portal</h1>
        <form className="mt-5" onSubmit={handleLogin}>
          <TextField
            type="email"
            label='Email Address'
            placeholder="Enter email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            fullWidth
            margin="normal"
            className="mb-3"
          />
          <TextField
            type={showPassword ? "text" : "password"}
            label='Password'
            placeholder="Enter password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            fullWidth
            margin="normal"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword((showPassword) => !showPassword)} >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
          <p className="my-4 text-start">
            By signing in you are accepting the Terms &amp; Conditions of
            CoreX connect (Pvt) Ltd
          </p>
          <Button
            disableElevation
            variant="contained"
            style={{ borderRadius: '10px', background: 'var(--slingRed)' }}
            size="large"
            className="p-3"
            type="submit" fullWidth
          >
            {isLoading === true ? <CircularProgress color="inherit" size={'1.5rem'} /> : "Login"}
          </Button>
        </form>
      </div>
      <div className="col-xl-8 only-desktop text-center">
        <div className="carousal-box py-5">
          <Carousel controls={false} className="my-5">
            <Carousel.Item>
              <img src='/images/ScreenShots/Dashboard.png' className="img-fluid carousal-box-img" alt="carousel" />
            </Carousel.Item>
            <Carousel.Item>
              <img src='/images/ScreenShots/OnsiteBattery.png' className="img-fluid carousal-box-img" alt="carousel" />
            </Carousel.Item>
            <Carousel.Item>
              <img src='/images/ScreenShots/SwapStations.png' className="img-fluid carousal-box-img" alt="carousel" />
            </Carousel.Item>
            <Carousel.Item>
              <img src='/images/ScreenShots/DistributionCentres.png' className="img-fluid carousal-box-img" alt="carousel" />
            </Carousel.Item>
            <Carousel.Item>
              <img src='/images/ScreenShots/LogisticDrivers.png' className="img-fluid carousal-box-img" alt="carousel" />
            </Carousel.Item>
          </Carousel>
        </div>
      </div>
    </div>
  );
}