import React, { useContext } from 'react'
import { Avatar, IconButton } from '@mui/material'
import NotificationsIcon from '@mui/icons-material/Notifications';
import './Header.css'
import { useLocation } from 'react-router-dom';
import { Menu } from '@mui/icons-material';
import { useProSidebar } from 'react-pro-sidebar';
import { UserContext, UserContextProps } from '../../context/UserContext';

function Header() {
  const { user } = useContext(UserContext) as UserContextProps;
  const location = useLocation()
  const { toggleSidebar } = useProSidebar();

  return (
    <div
      className='mb-3 flex-between flex-wrap'
      style={{ display: location.pathname === '/' || location.pathname === '/404' ? 'none' : 'flex' }}
    >
      <div className='only-mobile'>
        <IconButton onClick={() => toggleSidebar()}>
          <Menu />
        </IconButton>
      </div>
      {location.pathname === '/dashboard' ? (
        <h2>Dashboard</h2>
      ) : location.pathname === '/bulk_charging' ? (
        <h2>Bulk Charging Sites</h2>
      ) : location.pathname === '/distribution' ? (
        <h2>Distribution Centres</h2>
      ) : location.pathname === '/swapping' ? (
        <h2>Swapping Stations</h2>
      ) : location.pathname === '/logistic' ? (
        <h2>Logistic Drivers</h2>
      ) : location.pathname === '/staff' ? (
        <h2>Staff Management</h2>
      ) : location.pathname === '/inUse' ? (
        <h2>In Use Batteries</h2>
      ) : null}
      <div className='flex-between'>
        {/* <Badge variant='dot' overlap="circular"> */}
        <IconButton size='large' style={{ backgroundColor: 'var(--foreground)' }}>
          <NotificationsIcon />
        </IconButton>
        {/* </Badge> */}
        <div>
          <div className='only-desktop'>
            <div className='header-user'>
              <Avatar sizes='large' src={user?.profilePic ? user?.profilePic : '/images/User.jpg'} />
              <div className='mx-3'>
                <h6 className='my-0'>{user?.name}</h6>
                <p className='my-0 text-muted'>{user?.email}</p>
              </div>
            </div>
          </div>
          <div className='only-mobile ms-3'>
            <Avatar sizes='large' src={user?.profilePic ? user?.profilePic : '/images/User.jpg'} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Header