import firebase from "firebase/compat/app";
import "firebase/compat/auth"; // for authentication
import "firebase/compat/firestore"; // for cloud firestore
import "firebase/compat/storage"; // for cloud storage

const firebaseConfig = {
  apiKey: "AIzaSyCYfzgkQ3p-5ZLYPQfP_iHaukWXgyKtb9s",
  authDomain: "corex-connect.firebaseapp.com",
  projectId: "corex-connect",
  storageBucket: "corex-connect.appspot.com",
  messagingSenderId: "421875766684",
  appId: "1:421875766684:web:f2cdd14219259aa8702459"
};

firebase.initializeApp(firebaseConfig);

export default firebase;