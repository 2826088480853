import React from 'react';
import { Button, ButtonProps, TextField, TextFieldProps } from '@mui/material';
import './CustomMui.css'

export function FilledButton(props: ButtonProps) {
  return (
    <Button
      onClick={props.onClick}
      disableElevation
      size='large'
      variant='contained'
      fullWidth={props.fullWidth}
      type={props.type}
      startIcon={props.startIcon}
      endIcon={props.endIcon}
      className={props.className + ' grey-btn' || 'grey-btn'}
    >
      {props.children}
    </Button>
  );
};

export function PrimaryButton(props: ButtonProps) {
  return (
    <Button
      onClick={props.onClick}
      size='large'
      variant='contained'
      type={props.type}
      disabled={props.disabled}
      disableElevation
      className={props.className + ' primary-btn' || 'primary-btn'}
    >
      {props.children}
    </Button>
  );
};

export function CustomTextField(props: TextFieldProps) {
  return (
    <TextField
      required
      fullWidth
      size='small'
      margin='normal'
      inputProps={props.inputProps}
      type={props.type}
      label={props.label}
      placeholder={props.placeholder}
      onChange={props.onChange}
    />
  );
};