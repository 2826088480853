import React, { createContext, useEffect, useState } from "react";
import { ILoginUser } from "../helpers/Interfaces";
import { getLocalStorageUser } from "../helpers/HelperFunctions";

export interface UserContextProps {
  user: ILoginUser;
  updateUser: (user: ILoginUser) => void;
}

export const UserContext = createContext<UserContextProps | null>(null);

export function UserContextProvider({ children }: { children: React.ReactNode }) {
  const [user, setUser] = useState<ILoginUser>({ uid: "", email: "", name: "", userRole: "", profilePic: "" });

  useEffect(() => {
    if (!user.uid) {
      let user = getLocalStorageUser();
      if (user.uid) {
        setUser(user);
      }
    }
  }, [user])

  function updateUser(newUser: ILoginUser) {
    setUser(newUser);
  }

  return (
    <UserContext.Provider value={{ user, updateUser }}>
      {children}
    </UserContext.Provider>
  );
}