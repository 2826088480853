import moment from "moment";
import { useState } from "react";
import { Form } from "react-bootstrap";
import { Drawer, IconButton } from "@mui/material";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { IAttendance } from "../../helpers/Interfaces";

interface AttendanceDrawerProps {
  isOpen: boolean
  attendanceArray: IAttendance[] | undefined
  onClose: CallableFunction
}

function AttendanceDrawer(props: AttendanceDrawerProps) {
  const [filterMonth, setFilterMonth] = useState(new Date().getMonth())
  const [filterYear, setFilterYear] = useState(new Date().getFullYear())

  return (
    <Drawer open={props.isOpen} anchor="right" onClose={() => props.onClose()}>
      <div className="p-3">
        <div className="flex-between mb-4">
          <IconButton onClick={() => props.onClose()}>
            <ArrowForwardIosIcon />
          </IconButton>
          <h3>Attendance Information</h3>
        </div>
        <div className="flex-between mb-4">
          <Form.Select
            onChange={(e) => { setFilterYear(parseInt(e.target.value)) }}
            required value={filterYear} className="me-2"
          >
            <option value={2023}>2023</option>
            <option value={2024}>2024</option>
          </Form.Select>
          <Form.Select
            onChange={(e) => { setFilterMonth(parseInt(e.target.value)) }}
            required value={filterMonth} className="ms-2"
          >
            <option value={0}>January</option>
            <option value={1}>February</option>
            <option value={2}>March</option>
            <option value={3}>April</option>
            <option value={4}>May</option>
            <option value={5}>June</option>
            <option value={6}>July</option>
            <option value={7}>August</option>
            <option value={8}>September</option>
            <option value={9}>October</option>
            <option value={10}>November</option>
            <option value={11}>December</option>
          </Form.Select>
        </div>
        <div className="table-responsive">
          <table className='table table-hover align-middle'>
            <thead>
              <tr>
                <th>Date</th>
                <th>Check In</th>
                <th>Check Out</th>
                <th>Remarks</th>
              </tr>
            </thead>
            <tbody>
              {props.attendanceArray?.filter((item: IAttendance) => item.date.getMonth() === filterMonth && item.date.getFullYear() === filterYear).map((attendance: IAttendance, index: number) => {
                return (
                  <tr key={index} className="pointer">
                    <td>{moment.utc(attendance.date).local().format("DD MMM YYYY")}</td>
                    {attendance.checkIn ? (
                      <td>{moment(attendance.checkIn.toMillis()).format('hh:mm A')}</td>
                    ) : (
                      <td>No recorded</td>
                    )}
                    {typeof attendance.checkOut !== "string"  ? (
                      <td>{moment(attendance.checkOut.toMillis()).format('hh:mm A')}</td>
                    ) : (
                      <td>Still working</td>
                    )}
                    <td>{attendance.remarks}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </Drawer>
  );
}

export default AttendanceDrawer
