import React, { useEffect, useState } from 'react'
import firebase from '../../Services/FirebaseConfig'
import { useLocation } from 'react-router-dom'
import { Avatar, LinearProgress } from '@mui/material'
import AttendanceDrawer from './AttendanceDrawer'
import { IAttendance, IStaffMember } from '../../helpers/Interfaces'
import { PrimaryButton } from '../../components/CustomMui/CustomMui'
import { Add } from '@mui/icons-material'
import AddAdminModal from './AddUserModal'
import moment from 'moment'
import { toast } from 'react-toastify'

function Staff() {
  const [staff, setStaff] = useState([] as IStaffMember[])
  const [openDrawer, setOpenDrawer] = useState(false as boolean)
  const [selectedEmployer, setSelectedEmployer] = useState(null as IStaffMember | null)
  const [showAddModal, setShowAddModal] = useState(false as boolean)
  const location = useLocation()
  const [isLoading, setIsLoading] = useState(false as boolean)

  useEffect(() => {
    getStaff()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  async function getStaff() {
    try {
      setIsLoading(true);
      let userCollectionRef = firebase.app().firestore().collection("users");

      const res = await userCollectionRef.get();

      const staffPromises = res.docs.map(async (doc) => {
        const employee = doc.data() as IStaffMember;

        const attendanceRes = await userCollectionRef.doc(employee.uid).collection("attendance").get();

        const attendanceArray: IAttendance[] = attendanceRes.docs.map((attendanceDoc) => {
          const attendance = attendanceDoc.data() as IAttendance;
          attendance.date = new Date(moment(attendanceDoc.id, 'YYYY-MM-D').format('YYYY-MM-DD'));
          return attendance;
        });

        employee.attendanceArray = attendanceArray.sort((a, b) => (a.date as any) - (b.date as any));
        return employee;
      });

      const staffWithAttendance = await Promise.all(staffPromises);
      setStaff(staffWithAttendance);
    } catch (error: any) {
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  }

  if (isLoading) {
    return (
      <LinearProgress/>
    )
  }

  return (
    <div className='white-box'>
      <div className="text-end mb-3">
        <PrimaryButton onClick={() => setShowAddModal(true)}>
          <Add />
        </PrimaryButton>
      </div>
      <div className="table-responsive">
        <table className='table table-hover align-middle'>
          <thead>
            <tr>
              <th></th>
              <th>Name</th>
              <th>Phone</th>
              <th>Email</th>
              <th>Designation</th>
            </tr>
          </thead>
          <tbody>
            {staff?.map((employee: IStaffMember, index: number) =>
              <tr
                key={index} className='cursor-pointer'
                onClick={() => { setOpenDrawer(true); setSelectedEmployer(employee) }}
              >
                <td><Avatar sizes='large' src={employee?.profilePic ? employee?.profilePic : '/images/User.jpg'} /></td>
                <td>{employee.name}</td>
                <td><a className='text-blue' href={`tel:${employee?.contact}`}>{employee?.contact}</a></td>
                <td><a className='text-blue' href={`mailto:${employee?.email}`}>{employee?.email}</a></td>
                <td>{employee?.designation}</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <AttendanceDrawer
        isOpen={openDrawer}
        onClose={() => { setOpenDrawer(false) }}
        attendanceArray={selectedEmployer?.attendanceArray}
      />
      <AddAdminModal
        show={showAddModal}
        onHide={() => setShowAddModal(false)}
        reload={() => getStaff()}
      />
    </div>
  )
}

export default Staff