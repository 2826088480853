import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { CustomTextField, PrimaryButton } from '../../components/CustomMui/CustomMui'
import firebase from '../../Services/FirebaseConfig'
import { toast } from 'react-toastify';
import { CircularProgress } from '@mui/material';

interface AddUserModalProps {
  show: boolean
  onHide: CallableFunction
  reload: CallableFunction
}

function AddUserModal(props: AddUserModalProps) {
  const [name, setName] = useState('' as string)
  const [contact, setContact] = useState('' as string)
  const [email, setEmail] = useState('' as string)
  const [designation, setDesignation] = useState('' as string)
  const [isLoading, setIsLoading] = useState(false as boolean)

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setIsLoading(true)

    try {
      const userData = await firebase.app().functions("us-central1").httpsCallable("createUser")({
        email,
        password: '12345678'
      });

      await firebase.app().firestore().collection("users").doc(userData.data.userId).set({
        name,
        email,
        contact,
        userRole: "employee",
        designation,
        uid: userData.data.userId,
      });
      toast.success("Staff member registered successfully")
      props.reload()
      props.onHide()
    } catch (error: any) {
      toast.error(error.message)
    }
  }

  return (
    <Modal size='lg' show={props.show} onHide={() => props.onHide()} style={{ zIndex: 99999 }} centered>
      <Modal.Header closeButton>
        <h3>Add Staff Member</h3>
      </Modal.Header>
      <Modal.Body className='text-center'>
        <form className='row' onSubmit={handleSubmit}>
          <div className='col-xl-6'>
            <CustomTextField
              type='text'
              label='Name'
              placeholder="Enter the name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className='col-xl-6'>
            <CustomTextField
              type='phone'
              label='Contact Number'
              placeholder="Enter contact number"
              value={contact}
              onChange={(e) => setContact(e.target.value)}
            />
          </div>
          <div className='col-xl-6'>
            <CustomTextField
              type='email'
              label='Email'
              placeholder="Enter email address"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className='col-xl-6'>
            <CustomTextField
              type='text'
              label='Designation'
              placeholder="Enter employee designation"
              value={designation}
              onChange={(e) => setDesignation(e.target.value)}
            />
          </div>
          <div className="text-end mt-3">
            <PrimaryButton type='submit' disabled={isLoading}>
              {isLoading === true ? <CircularProgress color="inherit" /> : 'Register'}
            </PrimaryButton>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  )
}

export default AddUserModal